import Papa from 'papaparse'

const readFileContent = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = () => reject('something went wrong')
    fileReader.readAsText(file)
  })

const CSV = async (file: File): Promise<any> => {
  const content: any = await readFileContent(file)
  if (content) {
    return Papa.parse(content, {
      complete: (data) => data,
      header: true,
    })
  }
}

const convertImageToBinary = (file) =>
  new Promise((resolve) => {
    const image = new FileReader()
    image.readAsBinaryString(file)
    image.onload = () => resolve(image.result)
  })

const imageToBinary = async (file) => {
  return await convertImageToBinary(file)
}

const CsvFileTypes = ['csv', 'excel', 'xls']

const sheet = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parse: (file: File) =>
    file.type?.includes('image')
      ? imageToBinary(file)
      : CsvFileTypes.some((type) => file.type?.toLowerCase().includes(type))
      ? CSV(file)
      : '',
}

export default sheet
