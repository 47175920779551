import { ApiRoutes } from 'types'
import { authenticate, cookies } from 'services'

export const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}`

export const COMPANY: ApiRoutes = {
  DEFAULT: 'companies',
}
export const ADMIN: ApiRoutes = {
  DEFAULT: 'administrators',
}
export const ME: ApiRoutes = {
  DEFAULT: 'me',
}
export const CUSTOMERS: ApiRoutes = {
  DEFAULT: 'customers',
  BATCH: 'customers/batch',
}
export const LOGIN: ApiRoutes = {
  DEFAULT: 'login',
  REFRESH: 'token/refresh',
  SWITCH: 'switch-company',
  RESET: 'reset-password',
  SAVE: 'reset-password/save',
  CONFIRM_RESET: 'reset-password/save',
}

export const IMPORT: ApiRoutes = {
  CUSTOMERS: 'import/customers',
}
export const EXPORT: ApiRoutes = {
  CUSTOMERS: 'export/customers',
  CARDS: 'exports',
}

export const NOTIFICATION: ApiRoutes = {
  DEFAULT: 'notifications',
  EXPORT: 'export/notifications',
}

export const WALLET: ApiRoutes = {
  DEFAULT: 'wallets',
  REGISTRATION: 'registrations',
}

export const STATISTICS: ApiRoutes = {
  DEFAULT: 'statistics',
}
export const PWA: ApiRoutes = {
  DEFAULT: 'pwa',
  ANDROID: 'pwa/android',
  PUBLIC_KEY: 'google/vapid',
}

export const headers = (
  type: 'file' | 'default' = 'default',
  auth = true,
  token = cookies.get('token'),
): Record<string, string> => {
  let lastToken = token

  if (token) {
    // CHECK IF WE RECEIVE AN EXPIRED CUSTOM TOKEN, TAKE THE LAST TOKEN REFRESH IN COOKIES
    const expTimeToken = authenticate.jwt(token).exp
    if (expTimeToken && authenticate.hasExpired(expTimeToken)) {
      lastToken = cookies.get('token')
    }
  }

  if (auth) {
    switch (type) {
      case 'file':
        return {
          Authorization: `Bearer ${lastToken}`,
        }
      default:
        return {
          Authorization: `Bearer ${lastToken}`,
          'content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'fr-FR',
        }
    }
  }
  return { 'content-Type': 'application/json; charset=UTF-8' }
}

const API = (route: string): string => baseUrl + route

export default API
