import { styled } from 'theme'
import { colors } from 'theme/colors'
import { FiArrowRight, FiSearch } from 'react-icons/fi'

//Wrapper, Container, Icon, Field, Link, Arrow

export const Wrapper = styled.div`
  display: flex;
  height: 40px;
`
export const Container = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.color.accent};
  align-items: center;
  border-radius: 40px;
`
export const Icon = styled(FiSearch)`
  color: ${(props) => props.theme.color.accent};
  margin-left: 10px;
  margin-right: 6px;
  font-size: 18px;
`
export const Field = styled.input`
  font-size: 14px;
  border: none;
  line-height: 34px;
  margin-right: 12px;
  color: ${(props) => props.theme.color.accent};
  :focus {
    outline: none;
  }
`
export const Link = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: ${colors.mikadoYellow};
  &:hover {
    & svg {
      margin-left: 10px;
    }
  }
`
export const Arrow = styled(FiArrowRight)`
  transition: 200ms;
  margin-left: 5px;
  color: ${colors.mikadoYellow};
`
