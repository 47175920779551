import { CSVLink } from 'react-csv'
import { styled, css } from 'theme'
import { colors, globalColors } from 'theme/colors'
import { Button, ButtonGroup } from '@mui/material'
import { AiOutlineWarning, AiFillQuestionCircle, AiOutlineCheck } from 'react-icons/ai'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { GrSend } from 'react-icons/gr'

type SwitchButtonProps = {
  active?: boolean
}

type ButtonProps = {
  outlined?: boolean
  disabled?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - 580px);
  justify-content: flex-start;
`

export const Wrapper600 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - 580px);
  height: 600px;
  justify-content: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  min-width: 500px;
`

export const Input = styled.input`
  width: 444px;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.accent};
  color: ${(props) => props.theme.color.accent};
  padding: 7px 0;
  font-size: 14px;
  margin-bottom: 30px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.color.accent}50;
  }
`

export const Requirement = styled.p`
  font-weight: 500;
  font-size: 10px;
  font-style: italic;
  color: ${(props) => props.theme.color.alternative};
  margin: 2px 0;
`

export const WrapperCalendar = styled.div`
  width: 650px;
  min-width: 650px;
  background: ${colors.primaryWhite};
  padding-top: 10px;
  border-radius: 6px;
  height: 600px;
  overflow-x: hidden;
  margin-top: -10px;
  margin-left: 80px;
`
export const ButtonGroupSwitch = styled(ButtonGroup)`
  border-radius: 20px !important;
  border: 0;
  background: #e6e6f0;
  width: fit-content;
  margin-bottom: 20px;
  height: 35px;
`

export const DividerVertical = styled.div`
  height: 70vh;
  width: 1px;
  background: #cbcbcb;
  position: absolute;
  left: 800px;
`

export const SwitchButton = styled(Button)`
  border-radius: ${({ active }: SwitchButtonProps) => (active ? '20px !important;' : '0')};
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border: none !important;
  height: 35px;
  background: ${({ active }: SwitchButtonProps) =>
    active ? colors.mikadoYellow + '!important' : '#e6e6f0'};
  border: none;
  width: 220px;
  color: ${({ active }: SwitchButtonProps) => (active ? 'white !important' : '#85859d !important')};
`

export const Warning = styled(AiOutlineWarning)`
  color: #f59231;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: -5px;
`

export const TextArea = styled.textarea`
  height: 180px;
  border: 1px solid ${(props) => props.theme.color.accent};
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 8px;
  padding: 8px;
  font-size: 14px;
`

export const Upload = styled.button`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  height: 75px;
  border: 1px solid ${(props) => props.theme.color.accent};
  border-radius: 7px;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  padding: 15px;
  text-align: start;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export const Download = styled(CSVLink)`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  height: 75px;
  border: 1px solid ${(props) => props.theme.color.accent};
  border-radius: 7px;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  padding: 15px;
  text-align: start;
  cursor: pointer;
  text-decoration: none;
  &:focus {
    outline: none;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 400px;
  margin-top: 40px;
`

export const Title = styled.span`
  color: ${({ theme }) => theme.color.accent};
  font-size: ${({ size }: { size?: number }) => size || '16px'};
  font-weight: bold;
  margin: 0;
  line-height: 16px;
`

export const TextStyle = styled.span`
  color: ${({ theme }) => theme.color.accent};
  font-size: ${({ size }: { size?: number }) => size || '16px'};
  margin: 0;
  line-height: 16px;
`

export const DateInfosContainer = styled.div`
  background-color: #e6e6f0;
  padding: 10px;
  width: 460px;
  margin-top: 10px;
`

export const CalendarStyle = {
  head: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    background-color: #e6e6f0;
    width: 100%;
    padding-left: 20px;
  `,
  sendIcon: styled(GrSend)`
    font-size: 18px;
    margin-right: 15px;
  `,
}

export const QuestionIcon = styled(AiFillQuestionCircle)`
  font-size: 22px;
  color: ${globalColors.primary};
  cursor: pointer;
  margin-left: 10px;
  margin-top: -9px;
`

export const NextStep = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.primary};
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
`

export const PrevStep = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || props.theme.color.primary};
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
`

export const Prev = styled(FiArrowLeft)`
  color: ${(props) => props.color || props.theme.color.primary};
  margin-right: 5px;
`

export const Next = styled(FiArrowRight)`
  color: ${(props) => props.theme.color.primary};
  margin-left: 5px;
`

export const Submit = styled(Row)`
  justify-content: flex-end;
`

const disabledButton = css`
  color: ${colors.primaryWhite};
  background: ${colors.spanishGrey.light}50;
  border: none;
  cursor: not-allowed;
  & svg {
    color: ${colors.primaryWhite};
  }
  &:hover {
    transform: scale(1);
  }
`

const outlinedButton = css`
  color: ${colors.mikadoYellow};
  background: ${({ theme }) => theme.color.background};
  border: 1px solid ${colors.mikadoYellow};
`

export const ButtonStyled = styled.button`
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  height: 35px;
  border-radius: 54px;
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primaryWhite};
  background: ${({ theme }) => theme.color.primary};
  border: 1px solid ${colors.mikadoYellow};
  transition: 100ms;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
  ${({ outlined }: ButtonProps) => outlined && outlinedButton}
  ${({ disabled }: ButtonProps) => disabled && disabledButton}
`

export const ValidCheck = styled(AiOutlineCheck)`
  color: white;
  margin-left: 15px;
`
