import { BiCopy } from 'react-icons/bi'
import { FiEdit } from 'react-icons/fi'
import { styled } from 'theme'
import MOBILE_CONTENT from 'assets/mobile_content.svg'
import { colors, globalColors } from 'theme/colors'

export const MobileContent = styled.img.attrs({
  src: MOBILE_CONTENT,
})`
  position: absolute;
  top: 55px;
  width: 220px;
  height: 580px;
`

export const CompanyIcon = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 60px;
  height: auto;
  max-height: 50px;
  margin-right: 10px;
  margin-left: 10px;
`

export const TitleUnderline = styled.p`
  font-size: 15px;
  width: calc(100% - 80px);
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  z-index: 2;
`

export const TextContent = styled.p`
  width: 90%;
  padding: 10px;
  padding-bottom: 15px;
  padding-top: 5px;
  font-size: 14px;
  height: 53px;
  line-height: 24px;
  margin: 0;
  justify-content: center;
  margin-top: 5px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const NotifContainer = styled.div`
  width: ${(props: { width?: string }) => props.width || '210px'};
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 5px;
  border-radius: 5px;
`

export const TitleNotification = styled.p`
  z-index: 2;
  color: white;
  text-align: start;
  width: calc(100% - 65px);
  margin-bottom: 7px;
`

export const ModalTitle = styled.p`
  font-size: 22px;
  color: ${colors.eerieBlack};
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  margin: 0;
`

export const CopyIcon = styled(BiCopy)`
  color: ${globalColors.primary};
  font-size: 20px;
  margin-right: 10px;
`

export const EditIcon = styled(FiEdit)`
  color: ${(props: { disabled?: boolean }) =>
    props.disabled ? colors.spanishGrey.light : 'white'};
  opacity: ${(props: { disabled?: boolean }) => (props.disabled ? '0.7' : '1')};
  font-size: 20px;
  margin-right: 10px;
`
