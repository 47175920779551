import React, { useMemo, useContext, Dispatch, SetStateAction } from 'react'
import { Image, Popover } from 'antd'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'

import { MyInformationsContext } from 'providers'
import { Link } from 'react-router-dom'
import { FiCircle, FiEdit } from 'react-icons/fi'

import { ReactTable } from 'components'
import { CustomerCollectionResponse } from 'types/api'
import { TableRow, FixedTab, TabColumns, Icon, SortIcon } from './style'
import { CellButton } from 'components/Table/styles'
import * as GlobalStyle from 'styles/globalStyle'
import { routes } from 'router'

import APPLE_ROUNDED from 'assets/apple-rounded.svg'
import GOOGLE_ROUNDED from 'assets/google-rounded.svg'
import qrCodeIcon from 'assets/qr-code.png'

type OrderSort = {
  type: string
  value: 'asc' | 'desc'
}

type Props = {
  customers: CustomerCollectionResponse
  t: (string) => string
  orderSort: OrderSort
  setOrderSort: Dispatch<SetStateAction<OrderSort>>
  setIsLoad: Dispatch<SetStateAction<boolean>>
}

const CustomersTable = ({
  customers,
  t,
  orderSort,
  setOrderSort,
  setIsLoad,
}: Props): React.ReactElement => {
  const { myInformations } = useContext(MyInformationsContext)

  const secondFrontField = (cell) => {
    let firstFrontFieldIndex = undefined
    const cellField = cell.value?.fields?.find((field, index) => {
      if (field.location === 'front') {
        if (firstFrontFieldIndex !== undefined) {
          return field
        } else {
          firstFrontFieldIndex = index
        }
      }
    })

    return cellField
  }

  const handleChangeOrderSort = (type: string): void => {
    setIsLoad(true)
    const value = type === orderSort.type && orderSort.value === 'asc' ? 'desc' : 'asc'
    setOrderSort({ type: type, value })
  }

  const tableHeader = ({ tradKey, sortType }) => (
    <GlobalStyle.RowCenter>
      {t(tradKey)}
      <SortIcon onClick={() => handleChangeOrderSort(sortType)} />
    </GlobalStyle.RowCenter>
  )

  const cellRender = (value: string) => (
    <Popover content={value} trigger="hover">
      <GlobalStyle.TextEllipsis color="eerieBlack">{value}</GlobalStyle.TextEllipsis>
    </Popover>
  )

  const columns = useMemo(
    () => [
      {
        Header: tableHeader({ tradKey: 'customers.table.sharedKey', sortType: 'order[sharedKey]' }),
        accessor: 'sharedKey',
        type: 'text',
        Cell: ({ cell }: any) => (
          <Popover content={cell.value} trigger="hover">
            <p>{cell.value}</p>
          </Popover>
        ),
      },
      {
        id: 'col1',
        Header: tableHeader({
          tradKey: 'customers.table.headerLabel',
          sortType: 'order[headerLabel]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'header')?.label),
      },
      {
        id: 'col2',
        Header: tableHeader({
          tradKey: 'customers.table.headerValue',
          sortType: 'order[headerValue]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'header')?.value),
      },
      {
        id: 'col3',
        Header: tableHeader({
          tradKey: 'customers.table.frontLabel1',
          sortType: 'order[front1Label]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'front')?.label),
      },
      {
        id: 'col4',
        Header: tableHeader({
          tradKey: 'customers.table.frontValue1',
          sortType: 'order[front1Value]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'front')?.value),
      },
      {
        id: 'col5',
        Header: tableHeader({
          tradKey: 'customers.table.frontLabel2',
          sortType: 'order[front2Label]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(secondFrontField(cell)?.label),
      },
      {
        id: 'col6',
        Header: tableHeader({
          tradKey: 'customers.table.frontValue2',
          sortType: 'order[front2Value]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(secondFrontField(cell)?.value),
      },
      {
        id: 'col7',
        Header: tableHeader({
          tradKey: 'customers.table.backLabel1',
          sortType: 'order[back1Label]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'back')?.label),
      },
      {
        id: 'col8',
        Header: tableHeader({
          tradKey: 'customers.table.backValue1',
          sortType: 'order[back1Value]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.find((field) => field.location === 'back')?.value),
      },
      {
        id: 'col9',
        Header: tableHeader({
          tradKey: 'customers.table.backLabel2',
          sortType: 'order[back2Label]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.filter((field) => field.location === 'back')[1]?.label),
      },
      {
        id: 'col10',
        Header: tableHeader({
          tradKey: 'customers.table.backValue2',
          sortType: 'order[back2Value]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.filter((field) => field.location === 'back')[1]?.value),
      },
      {
        id: 'col11',
        Header: tableHeader({
          tradKey: 'customers.table.backLabel3',
          sortType: 'order[back3Label]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.filter((field) => field.location === 'back')[2]?.label),
      },
      {
        id: 'col12',
        Header: tableHeader({
          tradKey: 'customers.table.backValue3',
          sortType: 'order[back3Value]',
        }),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) =>
          // eslint-disable-next-line react/prop-types
          cellRender(cell.value?.fields?.filter((field) => field.location === 'back')[2]?.value),
      },
      {
        id: 'col13',
        Header: t('customers.table.barcodeType'),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) => {
          let barcodeType = '-'
          // eslint-disable-next-line react/prop-types
          const qrField = cell.value?.fields?.find((field) => field.type === 'qr_code')
          // eslint-disable-next-line react/prop-types
          const eanField = cell.value?.fields?.find((field) => field.type === 'ean')

          if (qrField) {
            barcodeType = t('customers.table.QrCode')
          } else if (eanField) {
            barcodeType = t('customers.table.EanCode')
          }
          return cellRender(barcodeType)
        },
      },
      {
        id: 'col14',
        Header: t('customers.table.barcodeLabel'),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) => {
          let label = '-'
          // eslint-disable-next-line react/prop-types
          const qrField = cell.value?.fields?.find((field) => field.type === 'qr_code')
          // eslint-disable-next-line react/prop-types
          const eanField = cell.value?.fields?.find((field) => field.type === 'ean')

          if (qrField) {
            label = qrField.label
          } else if (eanField) {
            label = eanField.label
          }
          return cellRender(label)
        },
      },
      {
        id: 'col15',
        Header: t('customers.table.barcodeValue'),
        accessor: 'wallet',
        type: 'text',
        Cell: ({ cell }: any) => {
          let value = '-'
          // eslint-disable-next-line react/prop-types
          const qrField = cell.value?.fields?.find((field) => field.type === 'qr_code')
          // eslint-disable-next-line react/prop-types
          const eanField = cell.value?.fields?.find((field) => field.type === 'ean')

          if (qrField) {
            value = qrField.value
          } else if (eanField) {
            value = eanField.value
          }
          return cellRender(value)
        },
      },
    ],
    [t, customers],
  )

  const endColumns = useMemo(
    () => [
      {
        id: 'col9',
        Header: t('customers.table.connected'),
        accessor: 'connected',
        type: 'text',
        Cell: ({ cell }: any) => (
          <p style={{ width: '60px', textAlign: 'center' }}>{t(cell.value ? 'yes' : 'no')}</p>
        ),
      },
      {
        id: 'col10',
        Header: t('customers.table.android'),
        accessor: 'google',
      },
      {
        id: 'col11',
        Header: t('customers.table.apple'),
        accessor: 'apple',
      },
      {
        id: 'col12',
        Header: t('customers.table.update'),
        accessor: 'id',
        type: 'button',
        icon: <FiEdit />,
        Cell: ({ cell }: any) => (
          <Link
            style={{ minHeight: '90px' }}
            to={`${routes.customersCardEdit.path}?customerId=${cell.value}`}
          >
            <CellButton heightMargins={true}>{cell.column.icon || <FiCircle />}</CellButton>
          </Link>
        ),
      },
      {
        id: 'col13',
        Header: t('customers.table.QrCode'),
        accessor: 'sharedKey',
        type: 'text',
        Cell: ({ cell }: any) => (
          <Image
            className="qrcode-img"
            width={25}
            src={qrCodeIcon}
            preview={{
              src: `${process.env.REACT_APP_API_ENDPOINT}qrcode/${myInformations?.company?.id}/${cell.value}`,
            }}
          />
        ),
      },
    ],
    [t, myInformations],
  )

  const tableInstance = useTable(
    {
      columns,
      data: customers?.['hydra:member'] || [],
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
  )

  const tableInstance2 = useTable(
    {
      columns: endColumns,
      data: customers?.['hydra:member'] || [],
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
  )

  return (
    <TableRow id="customers-dashboard">
      <TabColumns>
        <ReactTable tableInstance={tableInstance} />
      </TabColumns>

      <FixedTab>
        <Icon.google src={GOOGLE_ROUNDED} />

        <Icon.apple src={APPLE_ROUNDED} />
        <ReactTable tableInstance={tableInstance2} />
      </FixedTab>
    </TableRow>
  )
}

export default CustomersTable
