import { SetStateAction, Dispatch } from 'react'
import {
  company as companyService,
  customer as customerService,
  wallet as walletService,
} from 'services'
import { CompanyState, Notify } from 'types'
import { baseUrl } from 'api'

type Props = {
  company: CompanyState
  defaultCompanyStock: CompanyState
  companyId: string
  companyToken: string
  walletId: string
  t: (path: string) => string
  notify: ({ title, message, type }: Notify) => void
  setRedirect: Dispatch<SetStateAction<string>>
  customerId?: string
  companyWalletId?: string
  setIsLoad: Dispatch<SetStateAction<boolean>>
  customerSharedKey: string
}

function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    if (key === 'fields') {
      object.fields.forEach((field, index) =>
        Object.keys(field).forEach((fieldKey) =>
          formData.append(`fields[${index}][${fieldKey}]`, field[fieldKey]),
        ),
      )
    } else if (key !== 'id') {
      formData.append(key, object[key])
    }
  })
  return formData
}

function checkDataValidity({ data, customerId, t, notify, setIsLoad }): boolean {
  const errors = []
  // CHECK IF A FIELD IS EMPTY
  if (data.fields.some((field) => Object.keys(field).find((fieldKey) => !field[fieldKey]))) {
    errors.push(t(`edit.form.validation.fields`))
  }
  if (customerId && !data.sharedKey) {
    errors.push(t(`edit.form.validation.sharedKey`))
  }
  if (errors.length >= 1) {
    notify({ title: t('edit.form.error.company'), message: errors.join(' '), type: 'error' })
    setIsLoad(false)
    return false
  }

  return true
}

function filterEmptyFieldToCustomerCard(fields) {
  return fields.filter((field) => !Object.keys(field).find((fieldKey) => !field[fieldKey]))
}

export async function updateWallet({
  company,
  companyId,
  companyToken,
  defaultCompanyStock,
  walletId,
  setRedirect,
  t,
  notify,
  customerId,
  companyWalletId,
  setIsLoad,
  customerSharedKey,
}: Props): Promise<void> {
  // CREATE ARRAY WITH ALL CARDS FIELDS
  const fields = company.cards.content.cards
    .map((item) => {
      if (
        !customerId ||
        (customerId &&
          (!('isOverride' in item) || ('isOverride' in item && item.isOverride !== false)))
      ) {
        return {
          label: item.title,
          value: item.subtitle,
          type: item.type,
          location: item.location || 'back',
        }
      }
    })
    .filter((item) => item)

  // CREATE OBJECT with only the elements modified (except the fields) by comparing the defaultCompanyStock and the new company
  let updatedWallet = Object.keys(company).reduce((newCompany, field) => {
    if (company[field] && defaultCompanyStock[field] !== company[field] && field !== 'cards') {
      newCompany[field] = company[field]
    }
    return newCompany
  }, {} as any)

  // merging of modified elements and fields (the fields will always be sent in update
  updatedWallet = {
    ...updatedWallet,
    fields: customerId ? filterEmptyFieldToCustomerCard(fields) : fields,
  }

  // UPDATE A CUSTOMER CARD
  if (
    customerId &&
    checkDataValidity({
      data: { ...updatedWallet, wallet: `${baseUrl}wallet/${companyWalletId}` },
      customerId,
      notify,
      t,
      setIsLoad,
    })
  ) {
    // sort the no override fields (isOverride = provide from company wallet)
    updatedWallet.fields = updatedWallet.fields.filter(
      (field) =>
        !(field.location === 'back' && 'isOverride' in field && field.isOverride === false),
    )

    // PARSE BY IN FORM DATA
    const formBody = getFormData({
      ...updatedWallet,
      wallet: `/v1/wallets/${companyWalletId}`,
    })

    const requestResponseGestion = (res) => {
      setIsLoad(false)
      if (res.status === 200) {
        notify({
          title: t('edit.form.customer.success'),
          message: '',
          type: 'success',
        })
        setTimeout(() => setRedirect('/customers'), 1500)
      } else
        notify({
          title: t('edit.form.error.errorOccured.title'),
          message: t('edit.form.error.errorOccured.message'),
          type: 'error',
        })
    }
    customerService.wallet.update({ id: customerId }, formBody).then((resUpdateWalletCustomer) => {
      // CHECK IF THE ORIGINAL SHARED-KEY IS DIFFERENT TO COMPANY SHARED-KEY TO UPDATE THIS
      if (customerSharedKey !== company.sharedKey) {
        customerService.update({ id: customerId }, { sharedKey: company.sharedKey }).then(() => {
          requestResponseGestion(resUpdateWalletCustomer)
        })
      } else {
        requestResponseGestion(resUpdateWalletCustomer)
      }
    })
  } else if (checkDataValidity({ data: updatedWallet, customerId, notify, t, setIsLoad })) {
    // updated ponds and new company and wallet items
    companyService.settings
      .update({
        id: companyId,
        body: { welcomeNotification: company.welcomeNotification },
        customToken: companyToken,
      })
      .then(() => {
        // PARSE BY IN FORM DATA
        const formBody = getFormData(updatedWallet)
        walletService.update({ id: walletId, customToken: companyToken }, formBody).then((res) => {
          setIsLoad(false)
          if (res.status === 200) {
            notify({
              title: t('edit.form.successUpdate'),
              message: '',
              type: 'success',
            })
            setTimeout(() => setRedirect('/companies'), 1500)
          } else
            notify({
              title: t('edit.form.error.errorOccured.title'),
              message: t('edit.form.error.errorOccured.message'),
              type: 'error',
            })
        })
      })
  }
}
