import { styled, css } from 'theme'
import { colors } from 'theme/colors'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { AiOutlineCheck } from 'react-icons/ai'

type ButtonProps = {
  outlined?: boolean
  disabled?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const NextStep = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.primary};
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
`

export const PrevStep = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || props.theme.color.primary};
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
`

export const Prev = styled(FiArrowLeft)`
  color: ${(props) => props.color || props.theme.color.primary};
  margin-right: 5px;
`

export const Next = styled(FiArrowRight)`
  color: ${(props) => props.theme.color.primary};
  margin-left: 5px;
`

export const ValidCheck = styled(AiOutlineCheck)`
  color: white;
  margin-left: 15px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const disabledButton = css`
  color: ${colors.primaryWhite};
  background: ${colors.spanishGrey.light}50;
  border: none;
  cursor: not-allowed;
  & svg {
    color: ${colors.primaryWhite};
  }
  &:hover {
    transform: scale(1);
  }
`

const outlinedButton = css`
  color: ${colors.mikadoYellow};
  background: ${({ theme }) => theme.color.background};
  border: 1px solid ${colors.mikadoYellow};
`

export const Button = styled.button`
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  height: 35px;
  border-radius: 54px;
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primaryWhite};
  background: ${({ theme }) => theme.color.primary};
  border: 1px solid ${colors.mikadoYellow};
  transition: 100ms;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
  ${({ outlined }: ButtonProps) => outlined && outlinedButton}
  ${({ disabled }: ButtonProps) => disabled && disabledButton}
`

export const Submit = styled(Row)`
  justify-content: flex-end;
`
