import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LOGO from 'assets/logo-black.svg'
import { routes } from 'router'

import { Container, Wrapper, Logo, Title, Guard, Cross, Image404, Overlay } from '../styles'

type Props = {
  closeModal: () => void
  titleKey?: string
  textKey?: string
}

const ExpiredTokenModal = ({ closeModal, titleKey, textKey }: Props): ReactElement => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const close = () => {
    closeModal()
    push(routes.login.path)
  }

  return (
    <Overlay>
      <Wrapper>
        <Cross onClick={close} />
        <Logo src={LOGO} />
        <Container>
          <Title align={'center'} color={'primary'}>
            {t(titleKey || 'login.forgot.expiredToken.title')}
          </Title>
          <Guard align={'center'}>{t(textKey || 'login.forgot.expiredToken.text')}</Guard>
        </Container>
        <Image404 />
      </Wrapper>
    </Overlay>
  )
}

export default ExpiredTokenModal
