import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'

import { keyframes, styled } from 'theme'
import { colors } from 'theme/colors'
import { Text } from 'components/Typography'

type TableProps = {
  isAction?: boolean
}

type HeadProps = {
  col: number
}

type RowProps = {
  col: number
  isOdd?: boolean
  disabled?: boolean
}

const pulse = keyframes`
  0%{background-position: 0 50%}
  50%{background-position: 100% 50%}
  100%{background-position: 0 50%}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 74px);
  overflow: auto;
`

export const Container = styled.div`
  display: flex;
`

export const Skeleton = styled.div`
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.color.alternative}15;
  background: linear-gradient(270deg, ${({ theme }) => theme.color.alternative}25, #ffffff);
  background-size: 400% 400%;
  animation: ${pulse} 2s ease infinite alternate-reverse;
`

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isAction }: TableProps) => (isAction ? '320px' : '100%')};
  background: ${({ theme }) => theme.color.background};
  overflow: auto;
  :not(:first-child) {
    margin-left: 10px;
  }
  // split table only
  :not(:only-child) {
    width: calc(100% - 320px);
  }
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.alternative}10;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.accent};
    border-radius: 5px;
    border: none;
  }
  scrollbar-color: ${({ theme }) => theme.color.accent} !important;
  scrollbar-height: 10px !important;
`

export const Body = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-height: 40vh;
`

export const HeadTable = styled.div`
  z-index: 8;
  position: absolute;
  width: calc(100% - 460px);
  background: ${({ theme }) => theme.color.background};
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.alternative}20;
  & p {
    min-width: calc(100% / ${(row: HeadProps) => row.col});
    justify-content: center;
    font-weight: ${({ theme }) => theme.font.bold};
    & svg {
      margin-left: 10px;
      cursor: pointer;
    }
    :first-child {
      justify-content: flex-start;
    }
  }
`

export const Row = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  opacity: ${(row: RowProps) => (row.disabled ? 0.2 : 1)};
  background: ${(row: RowProps) =>
    row.isOdd ? `${colors.greyish}90` : (props) => props.theme.color.background};
  & p {
    min-width: calc(100% / ${(row: RowProps) => row.col});
    justify-content: center;
    :first-child {
      justify-content: flex-start;
    }
    :last-child {
      justify-content: flex-end;
    }
  }
  & button {
    margin: -3px calc((100% / ${(row: RowProps) => row.col}) / 2 - 10px);
    :last-child {
      margin-right: -10px;
    }
  }
`

export const Cell = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.accent};
  font-size: 12px;
  margin: 3px 0;
`

export const CellButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border: none;
  border-radius: 20px;
  margin: ${(props: { heightMargins?: boolean }) =>
    props.heightMargins ? '5px 0 5px 16px !important' : '0'};
  background: ${({ theme }) => theme.color.background};
  box-shadow: ${({ theme }) => theme.shadow};
  transition: 200ms;
  cursor: pointer;
  :not(:disabled) svg {
    color: ${({ theme }) => theme.color.primary};
  }
  :hover:not(:disabled) {
    transform: scale(1.2);
    & svg {
      transform: scale(1.2);
    }
  }
  :disabled {
    color: ${({ theme }) => theme.color.alternative};
    cursor: default;
  }
  :focus {
    outline: none;
  }
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

export const Prev = styled(FiChevronLeft)`
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
  color: ${(props) => props.theme.color.alternative};
`

export const Next = styled(FiChevronRight)`
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
  color: ${(props) => props.theme.color.alternative};
`

export const Empty = styled(Text)`
  font-style: italic;
  width: 100%;
  text-align: center;
  line-height: 100px;
`
