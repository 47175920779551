import { BiBuildings } from 'react-icons/bi'
import { FiArrowRight, FiBell, FiKey, FiPlus, FiUsers } from 'react-icons/fi'
import { Link as Router } from 'react-router-dom'

import { css, device, styled } from 'theme'
import { colors, globalColors } from 'theme/colors'

type WrapperProps = {
  marginLeft?: number
  marginRight?: number
  marginTop?: number
  marginBottom?: number
}

export const margin = css`
  margin-top: ${(wrapper: WrapperProps) => wrapper.marginTop && `${wrapper.marginTop}px`};
  margin-left: ${(wrapper: WrapperProps) => wrapper.marginLeft && `${wrapper.marginLeft}px`};
  margin-right: ${(wrapper: WrapperProps) => wrapper.marginRight && `${wrapper.marginRight}px`};
  margin-bottom: ${(wrapper: WrapperProps) => wrapper.marginBottom && `${wrapper.marginBottom}px`};
`

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
  ${() => margin}
  ${device.md} {
    margin-bottom: 30px;
  }
`
export const Square = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 36px 0 36px 36px;
  background: ${colors.greyish};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = {
  Company: styled(BiBuildings)`
    width: 68px;
    height: 68px;
    color: ${(props) => props.theme.color.accent};
  `,
  Notification: styled(FiBell)`
    width: 68px;
    height: 68px;
    color: ${(props) => props.theme.color.accent};
  `,
  Customer: styled(FiUsers)`
    width: 68px;
    height: 68px;
    color: ${(props) => props.theme.color.accent};
  `,
  Admin: styled(FiKey)`
    width: 68px;
    height: 68px;
    color: ${(props) => props.theme.color.accent};
  `,
}

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  margin-left: 20px;
`

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.mikadoYellow};
  display: flex;
  transition: 200ms;
  align-items: center;
  &:hover {
    color: ${globalColors.primary};
    & svg {
      margin-left: 10px;
    }
  }
`

export const Arrow = styled(FiArrowRight)`
  color: ${colors.mikadoYellow};
  margin-left: 5px;
  transition: 200ms;
`

const button = css`
  cursor: pointer;
  color: ${colors.primaryWhite};
  background-color: ${colors.mikadoYellow};
  height: 35px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border: none;
  font-size: 14px;
  transition: 200ms;
  text-decoration: none;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
`

export const Create = styled(Router)`
  ${button}
`

export const Run = styled.button`
  ${button}
`

export const Add = styled(FiPlus)`
  color: ${colors.primaryWhite};
  font-size: 20px;
  margin-right: 6px;
`

export const CounterSeparation = styled.hr`
  width: 1px;
  height: 120px;
  display: inline-block;
  border: none;
  margin-left: 40px;
  border-left: 1px solid ${colors.greyish};
`
