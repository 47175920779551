import { errors } from 'services/index'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const transformCsvResponse = async (promise, key) => {
  const csv = await promise.text()
  return {
    csv: csv.replace(/"/g, ''), //remove "" to parse in correct csv
    status: promise.status,
    response: errors.notify(key[promise.status], promise.status),
  }
}

export default transformCsvResponse
