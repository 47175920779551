import React, { Dispatch, SetStateAction, useRef, useContext, ReactElement } from 'react'
import { AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai'
import { Popover } from 'antd'
import moment from 'moment'

import { Notification } from 'types'
import { NotificationContext } from 'providers'
import {
  ButtonGroupSwitch,
  SwitchButton,
  Upload,
  Row,
  Download,
  Title,
  TextStyle,
  Wrapper,
  Container,
  DividerVertical,
  Input,
  Requirement,
  DateInfosContainer,
  CalendarStyle,
  QuestionIcon,
} from './styles'
import * as GlobaleSyle from 'styles/globalStyle'
import { Text } from 'components'
import sheet from 'services/files'
import Calendar from './Calendar'
import { BottomStepsProps } from './BottomSteps'
import BottomSteps from './BottomSteps'

type Props = {
  notification: Notification
  setNotification: Dispatch<SetStateAction<Notification>>
  t: (string, params?) => string
  bottomStepsProps: BottomStepsProps
  isNotificationSent: boolean
}

const Informations = ({
  notification,
  setNotification,
  t,
  bottomStepsProps,
  isNotificationSent,
}: Props): ReactElement => {
  const { notify } = useContext(NotificationContext)
  const uploadCsvRef = useRef(null)
  const csvModel = [[`shared-key`]]

  const uploadCsvModal = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorNotify = () => {
      notify({
        title: t('notification.form.csv.error'),
        message: '',
        type: 'warning',
      })
    }
    const file = event.target.files[0]
    const fileContent = await sheet.parse(file)
    if (fileContent) {
      const customersKey = []
      fileContent.data?.forEach(
        (customer) => customer[`shared-key`] && customersKey.push(customer[`shared-key`]),
      )
      if (customersKey.length) {
        setNotification({ ...notification, customers: customersKey })
        notify({
          title: t('notification.form.csv.success', { count: customersKey.length }),
          message: '',
          type: 'success',
        })
      } else {
        errorNotify()
      }
    } else {
      errorNotify()
    }
  }

  const parseDate = (date) => {
    const newDate = new Date(date)
    const parseDate = `${('0' + newDate.getDate().toString()).slice(-2)}/${(
      '0' + (newDate.getMonth() + 1).toString()
    ).slice(-2)}/${newDate.getFullYear()}`
    const parseTime = moment(date).format('HH:mm')
    return {
      date: parseDate,
      time: parseTime,
    }
  }

  return (
    <Wrapper>
      <Container>
        <GlobaleSyle.Row>
          <Title>{t('notification.form.name.title')}</Title>
          <Popover content={t('notification.form.name.information')}>
            <QuestionIcon />
          </Popover>
        </GlobaleSyle.Row>
        <Requirement>{t('maximumCharacters', { count: 255 })}</Requirement>
        <Input
          value={notification.name}
          placeholder={t('notification.form.name.title')}
          maxLength={255}
          onChange={(e) => setNotification({ ...notification, name: e.target.value })}
        />

        <ButtonGroupSwitch aria-label="button group">
          <SwitchButton
            onClick={() => setNotification({ ...notification, targetSending: true })}
            active={notification.targetSending}
          >
            {t('notification.form.targetSending')}
          </SwitchButton>
          <SwitchButton
            onClick={() => setNotification({ ...notification, targetSending: false })}
            active={!notification.targetSending}
          >
            {t('notification.form.noTargetSending')}
          </SwitchButton>
        </ButtonGroupSwitch>

        {notification.targetSending && (
          <>
            <Title style={{ marginBottom: '5px' }} size={16}>
              {t('notification.form.inputLabel.participantsTarget.title')}
            </Title>
            <Text margin={0} size={16}>
              {t('notification.form.inputLabel.participantsTarget.text')}
            </Text>
            <Row>
              <Download
                filename="Model_ciblage_participants.csv"
                data={csvModel}
                style={{ marginRight: '20px' }}
              >
                <AiOutlineDownload
                  style={{ marginRight: '10px', fontSize: '35px', color: '#22223a' }}
                />
                <TextStyle size={13}>{t('downloadFileExample')}</TextStyle>
              </Download>
              <Upload onClick={() => uploadCsvRef.current.click()}>
                <AiOutlineUpload style={{ marginRight: '10px', fontSize: '22px' }} />
                <TextStyle size={13}>{t('uploadFile')}</TextStyle>
              </Upload>
              {/* INPUT FILE HIDDEN */}
              <input
                style={{ display: 'none' }}
                name="logo"
                type="file"
                accept=".csv"
                ref={uploadCsvRef}
                onChange={uploadCsvModal}
              />
            </Row>
            <Text margin={0} size={16}>
              {t('notification.form.targetingCount', {
                count: notification.customersTargeted || notification.customers.length,
              })}
            </Text>
          </>
        )}

        {isNotificationSent === false && (
          <ButtonGroupSwitch style={{ marginTop: '30px' }} aria-label="button group">
            <SwitchButton
              onClick={() => setNotification({ ...notification, definedSending: true })}
              active={notification.definedSending}
            >
              {t('notification.form.definedSending')}
            </SwitchButton>
            <SwitchButton
              onClick={() => setNotification({ ...notification, definedSending: false })}
              active={!notification.definedSending}
            >
              {t('notification.form.notDefinedSending')}
            </SwitchButton>
          </ButtonGroupSwitch>
        )}

        <DateInfosContainer>
          <CalendarStyle.sendIcon />
          <Title style={{ lineHeight: '25px', fontSize: '14px' }}>
            {notification.definedSending
              ? t('notification.form.sendingInfos.title', {
                  sentAtDate: parseDate(notification.sentAt)?.date,
                  sentAtTime: parseDate(notification.sentAt)?.time,
                  endAtDate: parseDate(notification.endAt)?.date,
                  endAtTime: parseDate(notification.endAt)?.time,
                })
              : t('notification.form.sendingInfos.titleDisabled', {
                  endAtDate: parseDate(notification.endAt)?.date,
                  endAtTime: parseDate(notification.endAt)?.time,
                })}
          </Title>
          <Text margin={1}>{t('notification.form.sendingInfos.text')}</Text>
        </DateInfosContainer>
        <BottomSteps {...bottomStepsProps} />
      </Container>
      <DividerVertical />
      <Calendar
        isNotificationSent={isNotificationSent}
        notification={notification}
        setNotification={setNotification}
        t={t}
      />
    </Wrapper>
  )
}

export default Informations
