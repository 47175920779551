import React, { useContext, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiUpload, HiDownload } from 'react-icons/hi'

import { NotificationContext } from 'providers'
import { Wrapper, DownloadButton, ImportButton } from './style'
import { customer as customerService } from 'services'
import DefineDelimiterModal from 'components/Modals/DefineDelimiter'
import ModalWarningDelimiter from 'components/Modals/ModalWarningDelimiter'
import { findCsvDelimiter } from 'utils'

type Props = {
  count: number
  importButtonRef: React.LegacyRef<HTMLInputElement> | any
  downloadCsv: () => void
}

const ImportExportCustomer = ({ count, importButtonRef, downloadCsv }: Props): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [isDefineDelimiterModalDisplayed, setIsDefineDelimiterModalDisplayed] =
    useState<boolean>(false)
  const [isModalWarningDelimiterDisplayed, setIsModalWarningDelimiterDispalyed] =
    useState<boolean>(false)
  const [csvDelimiter, setCsvDelimiter] = useState<string>(';')

  const importCsv = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetFile = () => {
      // Reset the value of input file import customer => to send the same file
      event.target.value = null
    }

    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = function (loadEvent) {
      const text = loadEvent.target.result
      const detectedDelimiter =
        typeof text === 'string' ? findCsvDelimiter({ text, possibleDelimiters: [',', ';'] }) : ','
      if (detectedDelimiter !== csvDelimiter && isModalWarningDelimiterDisplayed === false) {
        setIsModalWarningDelimiterDispalyed(true)
        resetFile()
      } else {
        const formData = new FormData()
        formData.append('csv', event.target.files[0])
        formData.append('delimiter', csvDelimiter)
        notify({ title: t('customers.csvImport.success'), message: '', type: 'success' })
        setIsDefineDelimiterModalDisplayed(false)
        customerService.import(formData)
        resetFile()
      }
    }

    reader.readAsText(file)
  }

  const handleValidDelimiter = () => {
    importButtonRef?.current?.click()
  }

  return (
    <Wrapper>
      <ModalWarningDelimiter isDisplayed={isModalWarningDelimiterDisplayed} />
      {isDefineDelimiterModalDisplayed && (
        <DefineDelimiterModal
          closeModal={() => setIsDefineDelimiterModalDisplayed(false)}
          callBackFunction={handleValidDelimiter}
          setCsvDelimiter={setCsvDelimiter}
          csvDelimiter={csvDelimiter}
        />
      )}
      {count < 1 && (
        <DownloadButton onClick={downloadCsv}>
          <HiDownload style={{ marginRight: 8 }} />
          {t('customers.downloadCustomerTemplate')}
        </DownloadButton>
      )}
      <ImportButton onClick={() => setIsDefineDelimiterModalDisplayed(true)}>
        <HiUpload style={{ marginRight: 8 }} />
        {t('customers.csvImport.button')}
      </ImportButton>
      <input
        id="upload-customer-csv"
        accept=".xls,.xlsx,.csv"
        onChange={importCsv}
        multiple={false}
        ref={importButtonRef}
        type="file"
        hidden
      />
    </Wrapper>
  )
}

export default ImportExportCustomer
