import React, { ReactElement, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  Label,
  Color,
  Hexa,
  Requirement,
  Upload,
  Preview,
  UploadIcon,
  Text,
  Media,
  Top,
  Select,
  ArrowDown,
  Card,
  Flatlist,
  CloseIcon,
  Add,
  Button,
} from 'components/FormCreateEditCard/styles'
import { Dropdown } from 'components'
import { colors } from 'theme/colors'
import { CompanyContext } from 'providers'
import { parseApiPicture } from 'utils'
import { FieldType, Cards as CardsType } from 'types'
import { MdOutlineQrCode2 } from 'react-icons/md'
import FIELD, { BARCODE_FIELD } from 'constants/fields'

type Cards = { cards: CardsType; id?: string }

const Formatting = ({
  selectedSteps,
  cards,
  location,
}: {
  selectedSteps: string
  cards: Cards
  location: string
}): ReactElement => {
  const { t } = useTranslation()
  const mediaInput = useRef(null)
  const dropdownRef = useRef(null)
  const { setCompany, company } = useContext(CompanyContext)

  const [dropdownActive, setDropdownActive] = useState<number>(null)

  const setIcon = (type) => {
    switch (type) {
      case FIELD.TEXT:
        return <MdOutlineQrCode2 />
    }
  }

  function setContent(id: string, updatedCards): void {
    const cards = { ...company.cards, [id]: { cards: updatedCards, id } }
    setCompany({ ...company, cards })
  }

  const handleChange = (id, key, value) => {
    const mute = [...cards.cards]
    const cardIndex = mute.findIndex((obj) => obj.id == id)
    mute[cardIndex][key] = value
    mute[cardIndex].location = location
    setContent(cards.id, mute)
  }

  const handleChangeType = (id: number, key: string, value: FieldType): void => {
    handleChange(id, key, value)
    setDropdownActive(null)
  }

  const setDropdownList = (
    id: number,
  ): {
    text: string
    icon: ReactElement
    action: () => void
  }[] => {
    return Object.values(BARCODE_FIELD).map((type) => ({
      icon: setIcon(type),
      text: t(`edit.content.card.type.${type}`),
      action: () => handleChangeType(id, 'type', type),
    }))
  }

  const removeCard = (id) => {
    const sorted = cards.cards.filter((card) => card.id !== id)
    sorted.forEach((card, index) => {
      card.id = index
    })
    setContent(cards.id, sorted)
  }

  const addBlock = (): void => {
    setContent(cards.id, [
      ...cards.cards,
      {
        id: cards.cards[cards.cards.length - 1].id + 1,
        title: '',
        subtitle: '',
        type: BARCODE_FIELD.QR_CODE,
        location: 'barcode',
      },
    ])
  }

  const hasBarcode = useMemo(
    () => cards.cards.some((card) => card.location === 'barcode'),
    [cards.cards],
  )
  console.log(selectedSteps)

  return (
    <>
      {selectedSteps !== 'customer' && (
        <>
          <Label htmlFor="color">{t('edit.formatting.color')}</Label>
          <Color>
            <Hexa>{company.color || colors.mikadoYellow.toUpperCase()}</Hexa>
            <Input
              id="color"
              type="color"
              value={company.color || colors.mikadoYellow}
              onChange={(color) =>
                setCompany({ ...company, color: color.target.value.toUpperCase() })
              }
            />
          </Color>
        </>
      )}
      <Label>{t('edit.formatting.image.label')}</Label>
      <Requirement>{t('edit.formatting.image.size', { size: '750px x 246px' })}</Requirement>
      <Requirement>{t('edit.formatting.image.format')}</Requirement>
      <Upload
        onClick={() => mediaInput.current.click()}
        style={{ width: 444, padding: 0, height: 226 }}
      >
        {company.head ? (
          <Preview
            src={parseApiPicture({ img: company.head, defaultImg: UploadIcon })}
            style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: 7 }}
          />
        ) : (
          <>
            <UploadIcon />
            <Text>{t('edit.formatting.image.load')}</Text>
          </>
        )}
        <Media
          name="logo"
          type="file"
          accept="image/png, image/jpeg"
          ref={mediaInput}
          onChange={(head) => {
            setCompany({
              ...company,
              head: head.target.files[0],
            })
          }}
        />
      </Upload>
      {selectedSteps === 'customer' && (
        <Flatlist>
          <Button onClick={addBlock} outlined disabled={hasBarcode} margin="18px">
            <Add />
            {t('edit.content.addCodebar')}
          </Button>
          {cards.cards.map((card, index) => {
            if (card.location === 'barcode')
              return (
                <Card
                  marginBottom={index === cards.cards.length - 1 ? '0px' : '20px'}
                  key={card.id?.toString() || index + 1}
                >
                  <CloseIcon onClick={() => removeCard(card.id)} />
                  <Top>
                    <Select
                      onClick={() => setDropdownActive(dropdownActive === null ? card.id : null)}
                    >
                      {setIcon(card.type)}
                      {t(`edit.content.card.type.${card.type}`)}
                      <ArrowDown />
                    </Select>
                    <Dropdown
                      display={dropdownActive === card.id}
                      reference={dropdownRef}
                      selector
                      list={setDropdownList(card.id)}
                    />
                  </Top>
                  <Label>{t(`customers.table.barcodeLabel`)}</Label>
                  <Input
                    value={cards.cards[index]?.title}
                    maxLength={123}
                    placeholder={t('edit.content.card.placeholder')}
                    onChange={(event) => handleChange(card.id, 'title', event.target.value)}
                    marginBottom="0"
                    width="100%"
                  />
                  <Label>{t(`customers.table.barcodeValue`)}</Label>
                  <Input
                    value={cards.cards[index]?.subtitle}
                    maxLength={123}
                    placeholder={t(`edit.content.card.${card.type}`)}
                    onChange={(event) => handleChange(card.id, 'subtitle', event.target.value)}
                    width="100%"
                  />
                </Card>
              )
          })}
        </Flatlist>
      )}
    </>
  )
}

export default Formatting
