import React, { ReactElement, useState, useMemo, useContext } from 'react'
import { NotificationContext, UserContext } from 'providers'
import { FiCircle, FiTrash2, FiEdit } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'

import { TableSkeleton } from 'components/Skeletons'
import { Counter, Dialog, ReactTable, SearchFilter, Text } from 'components'
import { CellButton } from 'components/ReactTable/styles'
import { EMAIL_REGEX } from 'constants/regex'
import { useAdminQuery } from 'hooks'
import { DialogType } from 'types'
import { admin as adminService } from 'services'
import Layout from 'layouts'
import { Top } from './styles'
import { Pagination, Prev, Next } from 'components/Table/styles'

const Admin = (): ReactElement => {
  const { t } = useTranslation()
  const { add } = useAdminQuery()
  const { notify } = useContext(NotificationContext)
  const { user } = useContext(UserContext)

  const [idToEdit, setIdToEdit] = useState<string>('')
  const [newAdmin, setNewAdmin] = useState<boolean>(false)
  const [idToRemove, setIdToRemove] = useState<string>('')
  const [editedEmail, setEditedEmail] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const validateEmail = (email: string) => String(email).toLowerCase().match(EMAIL_REGEX)
  const { data, refetch, isLoading } = useQuery(
    [`admins${pageIndex}`, 'collection'],
    () => adminService.collection({ page: pageIndex }),
    {
      staleTime: 10,
    },
  )

  // TODO refacto using errors status/strings use notify in createAdmin promise
  // For example check updateAdmin function
  // const emailNotify = (status: string) => {
  //   notify({
  //     title: t(
  //       status === 'success' ? 'login.reset.email.admin_success.title' : 'login.reset.error.title',
  //     ),
  //     message: t(
  //       status === 'success'
  //         ? 'login.reset.email.admin_success.describe'
  //         : 'login.reset.error.describe',
  //     ),
  //     type: status,
  //   })
  // }

  const createAdmin = () => {
    if (validateEmail(editedEmail)) {
      setIsProcessing(true)
      add({ editedEmail }).then(() => {
        adminService.create({ email: editedEmail }).then((res) => {
          if (res.status === 201) {
            refetch()
            setNewAdmin(false)
            setEditedEmail('')
            setIsProcessing(false)
          }
          setIsProcessing(false)
          notify({
            title: t(res.response.message),
            message: '',
            type: res.status === 201 ? 'success' : 'error',
          })
        })
      })
    }
  }

  const updateAdmin = () => {
    if (validateEmail(editedEmail)) {
      setIsProcessing(true)
      adminService.update({ id: idToEdit }, { email: editedEmail }).then((res) => {
        notify(res.response)
        refetch().then(() => {
          setIdToEdit('')
          setEditedEmail('')
          setIsProcessing(false)
        })
      })
    }
  }

  const removeAdmin = () => {
    setIsProcessing(true)
    adminService.remove({ id: idToRemove }).then((res) => {
      notify(res.response)
      refetch().then(() => {
        setIdToRemove('')
      })
      setIsProcessing(false)
    })
  }

  const columns = useMemo(
    () => [
      { Header: t('admin.table.0'), accessor: 'email', sortable: true },
      {
        Header: t('admin.table.1'),
        accessor: 'edit',
        type: 'button',
        icon: <FiEdit />,
        Cell: ({ cell }: any) => (
          <>
            <CellButton onClick={() => setIdToEdit(cell.row.original.id)}>
              {cell.column.icon || <FiCircle />}
            </CellButton>
          </>
        ),
      },
      {
        Header: t('admin.table.2'),
        type: 'button',
        icon: <FiTrash2 />,
        Cell: ({ cell }: any) =>
          user.email !== cell.row.values.email && (
            <CellButton onClick={() => setIdToRemove(cell.row.original.id)}>
              {cell.column.icon || <FiCircle />}
            </CellButton>
          ),
      },
    ],
    [t],
  )

  const dialogAdd: DialogType = {
    title: t('admin.dialog.add.title'),
    content: t('admin.dialog.add.content'),
    type: 'success',
    input: {
      placeholder: t('admin.dialog.edit.placeholder'),
      onChange: (event) => setEditedEmail(event.target.value),
    },
    action: {
      dismiss: {
        onClick: () => setNewAdmin(false),
        text: t('admin.dialog.add.action.dismiss'),
      },
      active: {
        onClick: createAdmin,
        text: t('admin.dialog.add.action.confirm'),
      },
    },
  }

  const dialogRemove: DialogType = {
    title: t('admin.dialog.remove.title'),
    content: t('admin.dialog.remove.content'),
    mark: t('admin.dialog.remove.mark'),
    type: 'error',
    action: {
      dismiss: {
        onClick: () => setIdToRemove(''),
        text: t('admin.dialog.remove.action.dismiss'),
      },
      active: {
        onClick: removeAdmin,
        text: t('admin.dialog.remove.action.confirm'),
      },
    },
  }
  const dialogEdit: DialogType = {
    title: t('admin.dialog.edit.title'),
    content: t('admin.dialog.edit.content'),
    type: 'warning',
    input: {
      placeholder: t('admin.dialog.edit.placeholder'),
      onChange: (event) => setEditedEmail(event.target.value),
    },
    action: {
      dismiss: {
        onClick: () => setIdToEdit(''),
        text: t('admin.dialog.edit.action.dismiss'),
      },
      active: {
        onClick: updateAdmin,
        text: t('admin.dialog.edit.action.confirm'),
      },
    },
  }

  const tableInstance = useTable(
    {
      columns,
      data: data?.['hydra:member'] || [],
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
  )

  const { setGlobalFilter, state } = tableInstance

  return (
    <Layout>
      {idToRemove && <Dialog {...dialogRemove} isLoading={isProcessing} />}
      {idToEdit && <Dialog {...dialogEdit} isLoading={isProcessing} />}
      {newAdmin && <Dialog {...dialogAdd} isLoading={isProcessing} />}
      <Top>
        <Counter
          type="admin"
          count={data?.['hydra:totalItems']}
          marginBottom={50}
          onClick={() => setNewAdmin(true)}
        />
        <SearchFilter
          placeholder={t('admin.searchAdmin')}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Top>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <ReactTable tableInstance={tableInstance} noItemsMaxWidth />

          <Pagination>
            {pageIndex !== 1 && <Prev onClick={() => setPageIndex(pageIndex - 1)} />}
            <Text accent>
              {t('table.pagination', {
                current: pageIndex,
                total: Math.ceil(data?.['hydra:totalItems'] / 30) || 0,
              })}
            </Text>
            {Math.ceil(data?.['hydra:totalItems'] / 30) > pageIndex && (
              <Next onClick={() => setPageIndex(pageIndex + 1)} />
            )}
          </Pagination>
        </>
      )}
    </Layout>
  )
}

export default Admin
