export const isValidToken = (token: string): boolean => {
  if (!token) {
    return false
  }
  const base64 = token.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  const payload = JSON.parse(jsonPayload)
  console.log(payload)

  if (!payload.exp) {
    return false
  }

  const currentTime = Math.floor(Date.now() / 1000)

  return payload.exp > currentTime
}
