import React, { FC, useState } from 'react'

import { ProviderProps, StatsContextProps, StatsState } from 'types'

export const StatisticsContext = React.createContext<StatsContextProps | undefined>(undefined)

StatisticsContext.displayName = 'Statistics'

export const initialState = {
  id: '',
  companies: 0,
  company: '',
  customers: 0,
  notifications: 0,
  googleTotal: 0,
  googleMonths: [],
  appleTotal: 0,
  appleMonths: [],
  customersUniqueRegistration: 0,
}

const Statistics: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [statistics, setStatistics] = useState<StatsState>(initialState)

  const clearStatsContext = () => {
    setStatistics(initialState)
  }

  return (
    <StatisticsContext.Provider value={{ statistics, setStatistics, clearStatsContext }}>
      {children}
    </StatisticsContext.Provider>
  )
}

export default Statistics
