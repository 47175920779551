import { BiSortAlt2 } from 'react-icons/bi'

import { styled, device, css } from 'theme'
import { colors } from 'theme/colors'
import ImportCustomerIcon from 'assets/import_customer.svg'

type WrapperProps = {
  marginLeft?: number
  marginRight?: number
  marginTop?: number
  marginBottom?: number
}

export const margin = css`
  margin-top: ${(wrapper: WrapperProps) => wrapper.marginTop && `${wrapper.marginTop}px`};
  margin-left: ${(wrapper: WrapperProps) => wrapper.marginLeft && `${wrapper.marginLeft}px`};
  margin-right: ${(wrapper: WrapperProps) => wrapper.marginRight && `${wrapper.marginRight}px`};
  margin-bottom: ${(wrapper: WrapperProps) => wrapper.marginBottom && `${wrapper.marginBottom}px`};
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 97%;
  position: relative;
`

export const FixedTab = styled.div`
  width: 550px;
  position: absolute;
  padding-left: 10px;
  right: 0;
  top: 0px;
  z-index: 10;
  background: white;
`

export const TabColumns = styled.div`
  width: calc(100% - 550px);
  overflow-x: scroll;
  &::-webkit-scrollbar-thumb {
    background: grey;
  }
  &::-webkit-scrollbar {
    height: 10px;
  }
`

const DefaultIcon = styled.img`
  width: 40px;
  object-fit: contain;
  position: absolute;
  top: -40px;
  box-shadow: 1px 1px 5px #d3d3d3;
  border-radius: 50%;
`
export const Icon = {
  google: styled(DefaultIcon)`
    right: 325px;
  `,
  apple: styled(DefaultIcon)`
    right: 233px;
  `,
}

export const ImportCustomerCard = {
  card: styled.div`
    background: #e6e6f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 500px;
    border-radius: 30px 0px 30px 30px;
    position: absolute;
    left: calc(50vw - 150px);
    top: 380px;
  `,
  icon: styled.img.attrs({ src: ImportCustomerIcon })`
    height: 60px;
    margin-bottom: 40px;
  `,
}

export const ImportButton = styled.button.attrs((props: any) => ({
  innerRef: props.innerRef,
}))`
  background-color: ${colors.primaryWhite};
  color: ${colors.mikadoYellow};
  padding: 8px 16px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 80px;
  border: 1px solid ${colors.mikadoYellow};
  cursor: pointer;
  transition: 300ms;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: none;
  }
`

export const DownloadButton = styled.div`
  background-color: ${colors.mikadoYellow};
  color: ${colors.primaryWhite};
  padding: 8px 16px;
  max-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 80px;
  border: 1px solid ${colors.mikadoYellow};
  cursor: pointer;
  transition: 300ms;
  margin-right: 30px;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: none;
  }
`

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
  ${() => margin}
  ${device.md} {
    margin-bottom: 30px;
  }
`

export const SortIcon = styled(BiSortAlt2)`
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: ${({ marginTop }: { marginTop?: number }) => (marginTop ? `${marginTop}px` : '0')};
`
