import React, { ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { EMAIL_REGEX, PASSWORD_REGEX } from 'constants/regex'
import LOGO from 'assets/logo-black.svg'
import FORGOT from 'assets/forgot.svg'
import NEW from 'assets/new.svg'
import {
  Illustration,
  Container,
  Validate,
  Checked,
  Loading,
  Overlay,
  Wrapper,
  Logo,
  Title,
  Guard,
  Rules,
  Info,
  Gulp,
  Dot,
  Cross,
} from '../styles'
import { Input } from 'components/index'
import ExpiredToken from './ExpiredToken'
import { login } from 'services'
import { getUrlParams } from 'utils'
import { routes } from 'router'
import { isValidToken } from 'utils/isTokenExpired'

type Props = {
  modalType: string
  setCloseModal: React.Dispatch<React.SetStateAction<boolean>>
  handleResetPassword: (Object) => void
}

const Modal = ({ modalType, setCloseModal, handleResetPassword }: Props): ReactElement => {
  const passwordTokenExpired = modalType.includes('reset')
    ? !isValidToken(getUrlParams().activationToken)
    : false

  const { t } = useTranslation()
  const [email, setEmail] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState<boolean>(false)
  const [password, setPassword] = useState<{ new: string; verif: string }>({ new: '', verif: '' })
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(passwordTokenExpired)
  const [required, setRequired] = useState<{ [key: string]: { valid: boolean; regex: RegExp } }>({
    charLength: { valid: false, regex: PASSWORD_REGEX.LENGTH },
    digit: { valid: false, regex: PASSWORD_REGEX.DIGIT },
    upper: { valid: false, regex: PASSWORD_REGEX.UPPER },
    special: { valid: false, regex: PASSWORD_REGEX.SPECIAL },
    lower: { valid: false, regex: PASSWORD_REGEX.LOWER },
  })

  const modalTypes = {
    new: modalType.includes('new'),
    forgot: modalType.includes('forgot'),
    reset: modalType.includes('reset'),
  }

  const defaultEmailsRequestParams = {
    replyTo: process.env.REACT_APP_EMAIL_REPLY,
    fromName: process.env.REACT_APP_EMAIL_NAME,
    fromEmail: process.env.REACT_APP_EMAIL_FROM,
  }

  const sendEmail = useQuery(
    'email',
    () => {
      return login.reset({
        ...defaultEmailsRequestParams,
        email: email,
        subject: t(`login.request.subject.${modalType}`),
        templateUrl: process.env.REACT_APP_URL + '/email_template/reinitialisation_mdp.html',
        redirectUrl: `${process.env.REACT_APP_URL}${routes.login.path}${
          modalTypes.forgot ? '#reset' : 'new'
        }`,
      })
    },
    { enabled: false },
  )

  const sendReset = useQuery(
    'email',
    () => {
      const email = getUrlParams().email
      const activationToken = getUrlParams().activationToken
      return login.save({
        ...defaultEmailsRequestParams,
        email: email,
        activationToken: activationToken,
        password: password.new,
        templateUrl:
          process.env.REACT_APP_URL + '/email_template/confirmation_reinitialisation_mdp.html',
        subject: t(`login.request.subject.confirm_reset`),
      })
    },
    { enabled: false },
  )

  const submitPromise = (response, email) => {
    if (response.data.status === 410) {
      setIsExpiredToken(true)
    }
    if (response.data) {
      handleResetPassword({
        success: response.data.status === 200,
        message: t(response.data?.response?.message),
        email: email,
      })
    }
    if (response.data.status === 200) {
      setCloseModal(false)
    }
    setIsProcessing(false)
  }

  const handleSubmit = () => {
    setIsProcessing(true)
    if (modalTypes.reset || modalTypes.new) {
      sendReset.refetch().then((r) => submitPromise(r, false))
    } else {
      sendEmail.refetch().then((r) => submitPromise(r, true))
    }
  }

  const handleChangeEmail = (event) =>
    setEmail((prevState) => (event.target.value !== prevState ? event.target.value : prevState))

  const handleValidatePassword = (event) =>
    setPassword((prevState) => ({ ...prevState, verif: event.target.value }))

  const shouldAllowSubmission = () =>
    Object.values(required).every((currentValue) => currentValue.valid) &&
    password.new === password.verif

  const handleChangePassword = (event) => {
    setPassword((prevState) => ({ ...prevState, new: event.target.value }))
    for (const key in required) {
      if (required[key].regex.test(event.target.value)) {
        setRequired((prevState) => ({ ...prevState, [key]: { ...required[key], valid: true } }))
      } else {
        setRequired((prevState) => ({ ...prevState, [key]: { ...required[key], valid: false } }))
      }
    }
  }

  const setTitle = () => {
    if (modalTypes.forgot) return t('login.forgot.title')
    if (modalTypes.new) return t('login.register.title')
    return t('login.reset.title')
  }

  const setGuard = () => {
    if (modalTypes.forgot) return t('login.forgot.guard')
    if (modalTypes.new) return t('login.register.guard')
    return t('login.reset.guard')
  }

  const setValidate = () => {
    if (modalTypes.forgot) return t('login.forgot.submit')
    return t('login.register.submit')
  }

  useEffect(() => setIsAllowedToSubmit(shouldAllowSubmission()), [password])
  useEffect(() => setIsAllowedToSubmit(EMAIL_REGEX.test(email)), [email])

  const resetForm = (
    <>
      <Rules>
        {Object.keys(required).map((rule) => (
          <Gulp key={rule}>
            {required[rule].valid ? <Checked /> : <Dot />}
            {t(`login.rules.${rule}`)}
          </Gulp>
        ))}
      </Rules>
      <Input
        icon
        noPadding
        margin={[0, 0, 0, 0]}
        value={password.new}
        onChange={handleChangePassword}
        placeholder={t('login.reset.password')}
        type="password"
      />
      <Input
        icon
        noPadding
        margin={[0, 0, 0, 0]}
        value={password.verif}
        onChange={handleValidatePassword}
        placeholder={t('login.reset.validate')}
        type="password"
      />
    </>
  )

  const emailForm = (
    <Input
      icon
      noPadding
      margin={[0, 0, 0, 0]}
      value={email}
      onChange={handleChangeEmail}
      placeholder={t('login.forgot.email')}
      type="email"
    />
  )

  return (
    <Overlay>
      {isExpiredToken ? (
        <ExpiredToken closeModal={() => setCloseModal(false)} />
      ) : (
        <Wrapper>
          <Cross onClick={() => setCloseModal(false)} />
          <Logo src={LOGO} />
          <Container>
            <Title>{setTitle()}</Title>
            <Guard>{setGuard()}</Guard>
            {modalTypes.forgot ? (
              <>
                <Info>{t('login.forgot.info')}</Info>
                {emailForm}
              </>
            ) : (
              resetForm
            )}
            <Validate disabled={!isAllowedToSubmit} onClick={() => !isProcessing && handleSubmit()}>
              {setValidate()}
              {isProcessing && <Loading />}
            </Validate>
          </Container>
          <Illustration src={modalTypes.new ? NEW : FORGOT} />
        </Wrapper>
      )}
    </Overlay>
  )
}

export default Modal
