import { FieldType } from 'types'

const FIELD: { [key: string]: FieldType } = {
  TEXT: 'text',
  LINK: 'website',
  LOCATION: 'location',
  PHONE: 'phone_number',
}

export const BARCODE_FIELD: { [key: string]: FieldType } = {
  QR_CODE: 'qr_code',
  EAN: 'ean',
}

export default FIELD
