import { Divider as AntdDivider } from 'antd'

import { styled } from 'theme'
import { colors } from 'theme/colors'
import { iphone, android } from 'assets'
import { FiUploadCloud } from 'react-icons/fi'
import { BsArrowLeftRight } from 'react-icons/bs'

type DeviceProps = {
  type: 'ios' | 'android'
}

export const Wrapper = styled.div`
  border-radius: 13px;
  background: ${colors.greyish};
  box-shadow: ${(props) => props.theme.shadow};
  padding: 80px 20px 30px;
  margin-top: -50px;
  transform: scale(0.8);
`

export const Device = styled.div`
  width: ${(device: DeviceProps) => (device.type === 'ios' ? '270px' : '262px')};
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  align-items: center;
  background-image: url(${(device: DeviceProps) => (device.type === 'ios' ? iphone : android)});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 30px;
  & > div {
    background: ${colors.primaryWhite} ${(device: DeviceProps) => device.type === 'ios' && '40'};
    box-shadow: ${(device: DeviceProps) =>
      device.type === 'ios' ? (props) => props.theme.shadow : 'none'};
  }
`

export const HeadBanner = styled.div`
  width: 100%;
  background-color: ${colors.marine};
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

export const Container = styled.div`
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px;
  border-radius: 8px;
  backdrop-filter: blur(27px);
  margin-top: -60px;
`
export const UploadIcon = styled(FiUploadCloud)`
  font-size: 24px;
  color: ${(props) => props.theme.color.accent};
`

export const SwitchArrow = styled(BsArrowLeftRight)`
  color: #f7931e;
  font-size: 30px;
  background-color: white;
  padding: 7px;
  border-radius: 50%;
  box-shadow: 1px 1px 2px #1b1b28;
  margin-left: 20px;
  cursor: pointer;
`

export const Icon = styled.img`
  &:first-child {
    margin-right: 30px;
  }
`

export const Divider = styled(AntdDivider)`
  margin-top: 12px;
  margin-bottom: 0px;
`

type WrapperProps = {
  color: string | null
}

type ImgProps = {
  hide: boolean
}

type BlockProps = {
  verso: boolean
}

export const LittleWalletStyle = {
  wrapper: styled.div`
    width: 85%;
    background: ${(background: WrapperProps) => background.color || colors.primaryWhite};
    padding-top: 10px;
    border-radius: 6px;
    height: 150px;
    overflow: hidden;
    box-shadow: 0px 0px 5px #afafaf;
  `,
  illustration: styled.img`
    margin: 5px 0;
    width: 90%;
    margin-left: 5%;
    height: 60px;
    object-fit: cover;
    visibility: ${(illustration: ImgProps) => illustration.hide && 'hidden'};
  `,
  user: styled.div`
    white-space: nowrap;
    overflow: hidden;
    width: 60%;
    padding-left: 10px;
    & p {
      margin: 0px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
  block: styled.div`
    margin-right: 20px;
    margin-bottom: 10px;
    width: 45%;
    margin-right: 2%;
    margin-left: 2%;

    & p {
      margin: 4px;
    }
    :last-child {
      text-align: left;
    }
  `,
}

export const W = {
  wrapper: styled.div`
    width: 100%;
    background: ${(background: WrapperProps) => background.color || colors.primaryWhite};
    padding-top: 10px;
    border-radius: 6px;
    height: 430px;
    margin-bottom: -250px;
    overflow-y: scroll;
  `,
  top: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin: ${({ margin }: { margin?: string }) => margin};
  `,
  logo: styled.img`
    visibility: ${(logo: ImgProps) => logo.hide && 'hidden'};
    width: 100px;
    height: 30px;
    object-fit: contain;
    object-position: left;
  `,
  user: styled.div`
    white-space: nowrap;
    overflow: hidden;
    width: 60%;
    padding-left: 10px;
    & p {
      margin: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
  illustration: styled.img`
    margin: 10px 0;
    width: 100%;
    height: 150px;
    object-fit: cover;
    visibility: ${(illustration: ImgProps) => illustration.hide && 'hidden'};
  `,
  row: styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: ${(device: DeviceProps) => (device.type === 'ios' ? '240px' : '220px')};
    padding: 0 10px;
    margin-bottom: 10px;
  `,
  block: styled.div`
    margin-right: 20px;
    width: ${(block: BlockProps) => (block.verso ? '85%' : 'auto')};
    & p {
      margin: 4px;
    }
    :last-child {
      text-align: left;
    }
  `,
  verso: {
    top: styled.div`
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 0 10px;
    `,
    title: styled.p`
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 20px;
      overflow-wrap: anywhere;
      color: ${(props) => props.color || props.theme.color.alternative};
    `,
    logo: styled.img`
      visibility: ${(logo: ImgProps) => logo.hide && 'hidden'};
      width: 70%;
      height: auto;
      max-height: 100px;
      object-fit: contain;
      margin-top: 20px;
    `,
  },
}
